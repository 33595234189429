export const Language = "en";

export const MODE = "production"; // ['test' , 'production','uat']
let api_url = "";
if (MODE === "production") api_url = "https://webapi.ebol.cl/v1/api/";
else if (MODE === "test") api_url = "https://testing-webapi.ebol.cl/v1/api/";
// else api_url = "http://ebolapi.syprojects.world:8182/api/";
// else api_url = "http://ebolapi.syprojects.world:6002/api/";
else api_url = "http://ebolapi.syprojects.world:6004/api/";
// else api_url = "http://45.77.246.170:8000/api/";
// else api_url = "https://testing-webapi.ebol.cl/v1/api/";

export const API_URL = api_url;

// export const API_URL = "https://webapi.ebol.cl/v1/api/";
// export const API_URL = "https://ebolapi.syprojects.world/v1/api/";
export const LIMIT = 5;
export const HEADER_ORIGIN_KEY = "p-source";
export const HEADER_ORIGIN = "cp";

export const updateNumber = value => {
  value = value.toString();
  let status = value.includes("-");
  if (status === false) {
    value =
      value.substr(0, value.length - 1) + "-" + value.substr(value.length - 1);
  }
  return value;
};
